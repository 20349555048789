/**
 * Animation triggered when the page loads.
 * Makes the effect of sliding down the curtains.
 * After the curtains effect finish the header slide in effect is triggered also all the presentation animations are run.
 */
export default function revealAnimations(gsap, presentationAnimations) {
    const revealCurtains = document.querySelector('[data-reveal-curtains]');
    if(revealCurtains) {
        const curtainType = revealCurtains.dataset.revealCurtainsDirection || 'vertical';
        const curtains = revealCurtains.querySelectorAll('div');
        const mainHeader = document.querySelector('header .header-navigation');
        // const anchors = document.querySelectorAll('a');


        /**
         * Animation of header sliding down
         * In resources/sass/layout/_header.scss line 14
         * I'm setting the starting position of the header (vertically -100px)
         * After 700ms (when curtains animation finish) I'm setting Y to 0 to slide the header in.
         */
        setTimeout(() => {
            mainHeader.classList.add("reveal"); // Executes all the parallax presentation animations after 700 ms (when header animation starts)
            presentationAnimations();
        }, 700);
        
        // gsap.set(mainHeader, { y: -100 })
        // gsap.to(mainHeader, {
        //     y: 0,
        //     duration: 0.8,
        //     delay: 0.7,
        //     ease: 'power2.in',
        //     onStart: () => {  // Executes all the parallax presentation animations after 700 ms (when header animation starts)
        //         presentationAnimations();
        //     }
        // });

        const tl = gsap.timeline();
        if(curtainType === 'horizontal') {
            tl.to(curtains, {
                x: '-100%',
                stagger: 0.2,
                duration: 0.55,
                delay: 0.1
            });
        } else {
            tl.to(curtains, {
                yPercent: 100,
                duration: 0.75,
                delay: 0.1,
                stagger: 0.2,
                ease: 'power2.inOut',
                display: 'none',
            });
        }

        // const touchEvent = 'touchstart' in window ? 'touchstart' : 'click';
        // anchors.forEach((anchor) => {
        //     const href = anchor.href;
        //     if(href && href !== '#') {
        //         anchor.addEventListener(touchEvent, (e) => {
        //             e.preventDefault();
        //             // tl.reverse();
        //             setTimeout(() => {
        //                 window.location.href = href;
        //             }, 500);
        //         });
        //     }
        // });
    }
}