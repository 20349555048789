export default function heroSliderDouble(Splide) {
	const slidersSection = document.querySelectorAll('[data-slider-double-section]');
    

    if(slidersSection.length){
        slidersSection.forEach((sliderSection) => {
            const sliderContent = sliderSection.querySelector('[data-double-splide-content]');
            const buttonsWrapper = sliderSection.querySelector('[data-splide-buttons-wrapper] .oval-buttons-slider');
            
            const splide = new Splide(sliderContent, {
                type: 'fade',
                arrows: false,
                autoplay: false,
                pagination: false,
                perPage : 1,
                focus  : 'center',
            }).mount();

            if(buttonsWrapper) {
                const sliderButtons = buttonsWrapper.querySelectorAll('button');

                sliderButtons.forEach((sliderButton) => {
                    sliderButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        let { Components: { Controller } } = splide;
                        if(sliderButton.classList.contains('splide__arrow--next')) {
                            Controller.go(Controller.getNext());
                            buttonsWrapper.classList.remove('active-left');
                        } else {
                            Controller.go(Controller.getPrev());
                            buttonsWrapper.classList.add('active-left');
                        }
                    })
                });
            }
        });
        
    }
}