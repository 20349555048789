export default function filtersReveal(gsap) {
    const animatedStaggedContent = document.querySelectorAll('#portfolioFilters [data-parallax-stagged-filters]');
    // Stagged filters overlap
    if(animatedStaggedContent.length) {
        animatedStaggedContent.forEach((parent) => {
            const duration = parseInt(parent.dataset.parallaxStaggedContentDuration) || 0.5;
            //const animationPercentStart = parent.dataset.parallaxAnimatedScrollStart || '40%';
            const children = parent.querySelectorAll('[data-parallax-stagged-filters-item]');
            const tlContent = gsap.timeline({
                scrollTrigger: {
                    trigger: parent,
                    toggleActions: 'play none none reverse'
                }
            });

            let delayDif = duration / children.length;
            children.forEach((element, index) => {
                // Set initial state
                initialState(gsap, element, 'reveal-from-top');
                tlContent.to(element, {
                    y: 0,
                    autoAlpha: 1,
                    duration: duration,
                    delay: delayDif * index
                }, 'start');
            });
        });
    }
}

const initialState = (gsap, elem, animationType) => {
    switch (animationType) {
        case 'reveal-from-top':
            gsap.set(elem, {
                y: elem.dataset.parallaxAnimatedStartValue || -50,
                autoAlpha: 0
            });
            break;
        default:
            gsap.set(elem, {
                autoAlpha: 0
            });
            break;
    }
}

export { filtersReveal };