/**
 * Get Device Type function
 * @returns 
 */
function getDeviceType() {
    const ua = navigator.userAgent;
    if (
        /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
        (window.matchMedia('only screen and (min-width: 768px)').matches &&
            window.matchMedia('only screen and (max-width: 1023px)').matches)
    ) {
        return 'tablet';
    } if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua,
        ) ||
        window.matchMedia('only screen and (max-width: 767px)').matches
    ) {
        return 'mobile';
    }
    return 'desktop';
}

/**
 * Check if device is iPhone, iPad or iPod
 * @returns 
 */
function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform);
}

export { getDeviceType, iOS }