export default function relatedPosts(Splide) {
    const sections = document.querySelectorAll('[data-related-post-section]')

    if(sections.length){
        sections.forEach((section) => {
            const sliderContent = section.querySelector('.splide');
            const sliderButtonsWrapper = section.querySelector('.news-related-posts-slide-arrows-content');
            const sliderButtons = sliderButtonsWrapper.querySelectorAll('button');
            const currentSlideIndicator = sliderButtonsWrapper.querySelector('.current-slide-number');

            let splide = new Splide(sliderContent, {
                mediaQuery: 'min',
                type: 'loop',
                arrows: false,
                autoplay: false,
                pagination: false,
                breakpoints: {
                    1024: {
                        destroy: true,
                    },
                    1023: {
                        perPage: 1,
                    }
                }
            });
            splide.mount();

            splide.on('move', (currentSlide) => {
                currentSlideIndicator.textContent = (currentSlide + 1); 
            });

            // Custom arrows logic.
            if(sliderButtons.length) {
                sliderButtons.forEach((sliderButton) => {
                    sliderButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        let { Components: { Controller } } = splide;
                        if(sliderButton.classList.contains('splide__arrow--next')) {
                            Controller.go(Controller.getNext());
                        } else {
                            Controller.go(Controller.getPrev());
                        }
                    })
                });
            }
        });
    }
}
