export default function optionsSelect (gsap){
    const sectionSelect = document.querySelectorAll('[data-has-select]')

    if(sectionSelect.length){
        
        sectionSelect.forEach((tabSection) => {
            const select = tabSection.querySelector('[data-select-parent] select')
            const tabsContainer = tabSection.querySelector('[data-tabs-container]');

            const animatedElements = tabSection.querySelectorAll('[data-animated]');

            select.addEventListener('change', (e) => {
                e.preventDefault();
                const targetTab = tabsContainer.querySelector(`#${e.target.value}`)
                if(targetTab){
                    const targetTabAnimatedElements = targetTab.querySelectorAll('[data-animated]');
                    resetTabs(tabsContainer, animatedElements, gsap);
                    targetTab.classList.add('active');
                    gsap.to(targetTabAnimatedElements, {
                        autoAlpha: 1,
                        stagger: 0.2,
                        duration: 0.75,
                        delay: 0.2
                    });

                }
            })
        });
    }
}

function resetTabs(tabsContainer, animatedElements, gsap) {
    const tabsContent = tabsContainer.querySelectorAll('.tabs-tabpanel');
    tabsContent.forEach((tabContent) => {
        tabContent.classList.remove('active');
    });
    gsap.set(animatedElements, {
        autoAlpha: 0
    });
}