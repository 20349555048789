import { gsap } from 'gsap';

const scrollGetNextSection = (button) => {
  const buttonScrollTo = button.getAttribute('data-scroll-to');
  const sectionParent = button.closest('main > *');

  if (buttonScrollTo !== 'default') {
    button.setAttribute('data-next-section', buttonScrollTo);
    return true;
  } else {
    if (sectionParent) {
      button.setAttribute('data-next-section', sectionParent.nextElementSibling.id);
      return true;
    } else {
      return false;
    }
  }
};

const setNextSection = (button) => {
  const nextSectionId = button.getAttribute('data-next-section');
  if (nextSectionId) {
    const nextSection = document.getElementById(nextSectionId);
    button.addEventListener('click', () => {
      gsap.to(window, {
        duration: 1,
        ease: 'power4.out',
        scrollTo: { y: nextSection, offsetY: 0 },
      });
    });
  }
};

const loadScrollButton = (button) => gsap.to(button, { duration: 0.3, alpha: 1, y: 0, delay: 0.5 });
const scrollToTopButton = document.getElementById('scroll-to-top');
if (scrollToTopButton) {
  scrollToTopButton.addEventListener('click', (event) => {
    event.preventDefault();
    gsap.to(window, {
      duration: 2.5,
      ease: 'back.out(1)',
      scrollTo: { y: 0 },
    });
    document.querySelector('main').focus();
  });
}

export { scrollGetNextSection, setNextSection, loadScrollButton };
