import { gsap } from 'gsap';
import 'vanilla-cookieconsent';
export default function cookieConsent() {
  // obtain plugin
  var cookieConsent = initCookieConsent();
  const cookiesDescription = document.querySelector('#cookiesDescription')
  const cookiesReject = document.querySelector('#cookiesReject')
  const cookiesAccept = document.querySelector('#cookiesAccept')

  // run plugin with your configuration
  cookieConsent.run({
    gui_options: {
      consent_modal: {
        layout: 'cloud', // box/cloud/bar
        position: 'bottom left', // bottom/middle/top + left/right/center
        transition: 'slide', // zoom/slide
        swap_buttons: true, // enable to invert buttons
      },
    },

    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    languages: {
      en: {
        consent_modal: {
          title: '',
          description: cookiesDescription.innerHTML,
          //description: 'Cookies Description Text Lorem Ipsum',
          primary_btn: {
            text: cookiesAccept.innerHTML,
            //text: 'Cookies Accept',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          }
        },
        settings_modal: {
          cookie_table_headers: [],
          blocks: [],
        },
      },
    },
    delay: 3000,
    onAccept: () => {
      const banner = document.getElementById('announcement');
      gsap.to(banner, {
        y: 0
      });
    },
  });

  // set aria-label by template content
  let buttonReject = document.querySelector('#c-s-bn')
  let buttonAccept = document.querySelector('#c-p-bn')

  if (buttonAccept && buttonReject) {
    buttonReject.setAttribute('aria-label', cookiesReject.getAttribute('aria-label'))
    buttonAccept.setAttribute('aria-label', cookiesAccept.getAttribute('aria-label'))
  }
}


