/**
 * Creates a re-usable accordion effect.
 * 
 * HTML structure:
 *  <div data-accordion> // can have data-accordion-responsive="[max breakpoint width value]" also data-accordion-multiple-opens
        <div data-accordion-item>  
            <button aria-label="Click to see services information" aria-haspopup="true" aria-expanded="false" role="tabpanel" data-accordion-trigger>
                <h3>Accordion trigger title</h3>
            </button>
            <div class="accordion-content" data-accordion-content>
                Some content
            </div>
        </div>
        <div data-accordion-item>  
            <button aria-label="Click to see services information" aria-haspopup="true" aria-expanded="false" role="tabpanel" data-accordion-trigger>
                <h3>Accordion trigger title</h3>
            </button>
            <div class="accordion-content" data-accordion-content>
                Some content
            </div>
        </div>
    </div>
 * CSS Styles:
    @see: /sass/snippets/_accordion_items.scss - accordions
 */

export default function gsapAccordion(gsap) {
    // Each group of accordions needs to have this dataset.
    const accordionContainers = document.querySelectorAll('[data-accordion]');
    if (accordionContainers.length) {
        accordionContainers.forEach((acc) => {
            const windowWidth = window.innerWidth;
            const responsiveBreakpoint = acc.dataset.accordionResponsive ? acc.dataset.accordionResponsive : 0;
            const multipleOpened = typeof acc.dataset.accordionMultipleOpens != 'undefined' ? true : false;
            // Only if not responsive restrictions or windowWidth less than the breakpoint defined in data-accordion-responsive
            if ((windowWidth <= responsiveBreakpoint) || responsiveBreakpoint === 0) {
                const touchEvent = 'touchstart' in window ? 'touchstart' : 'click';
                // Each of the accordion items inside the group.
                const accordionItems = acc.querySelectorAll('[data-accordion-item]');
                if(accordionItems.length) {
                    accordionItems.forEach((accordionItem) => {
                        // The button to toggle the accordion.
                        const accordionTrigger = accordionItem.querySelector('[data-accordion-trigger]');
                        if(accordionTrigger) {
                            // The content of the accordion.
                            let content = accordionItem.querySelector('[data-accordion-content]');
                            let isExpanded = accordionTrigger.dataset.accordionExpanded;

                            // If any trigger has property "expanded"
                            if (typeof isExpanded != 'undefined') {
                                gsap.to(content, {
                                    height: 'auto',
                                    autoAlpha: 1,
                                    duration: 0.2
                                });
                                content.setAttribute("aria-hidden","false");
                                accordionTrigger.setAttribute("aria-expanded","true");
                                accordionItem.classList.add('expanded');
                            }

                            // Click/touch on the accordion button.
                            accordionTrigger.addEventListener(touchEvent, (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.stopImmediatePropagation();
                                // If accordion is open.
                                if (accordionItem.classList.contains('expanded')) {
                                    accordionItem.classList.remove('expanded');
                                    gsap.to(content, {
                                        height: 0,
                                        autoAlpha: 0,
                                        duration: 0.2
                                    });
                                    content.setAttribute("aria-hidden","true");
                                    accordionTrigger.setAttribute("aria-expanded","false");
                                    accordionTrigger.classList.remove("active");
                                } else { // If accordion is closed.
                                    // Default behavior is to only have one item open, by using data-accordion-multiple-opens 
                                    // we can decide if we want to have multiple items open at the same time.
                                    if(!multipleOpened){
                                        const expandedItem = acc.querySelector('.expanded');
                                        if(expandedItem){
                                            let expandedContent = expandedItem.querySelector('.accordion-content');
                                            let expandedTrigger = expandedItem.querySelector('button');
                                            gsap.to(expandedContent, {
                                                height: 0,
                                                autoAlpha: 0,
                                                duration: 0.2
                                            });
                                            expandedContent.setAttribute("aria-hidden","true");
                                            expandedTrigger.setAttribute("aria-expanded","false");
                                            expandedTrigger.classList.remove("active");
                                            expandedItem.classList.remove('expanded');
                                        }
                                    }
    
                                    accordionItem.classList.add('expanded');
                                    gsap.to(content, {
                                        height: 'auto',
                                        autoAlpha: 1,
                                        duration: 0.2
                                    });
                                    content.setAttribute("aria-hidden","false");
                                    accordionTrigger.setAttribute("aria-expanded","true");
                                    accordionTrigger.classList.add("active");
                                }
                            });
                        }
                    });
                }
            }
        });
    }
}