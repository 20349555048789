export default function teamMembers(Splide, gsap, ScrollTrigger) {
    const sliderSection = document.querySelectorAll('[data-splide-with-thumbnails ]');
    if (sliderSection.length) {
        sliderSection.forEach((section) => {
            const sliderThumbs = section.querySelector('[data-splide-thumbnails]');
            const sliderContent = section.querySelector('[data-splide-content]');
            if(sliderThumbs && sliderContent){
                const sliderNextButton = section.querySelector('[data-splide-buttons] button');

                // Main slider instance.
                const main = new Splide(sliderContent, {
                    type: 'fade',
                    arrows: false,
                    autoplay: false,
                    pagination: false,
                    perPage: 1,
                    autoHeight: true,
                    height: 'auto',
                });

                // Thumbs slider instance.
                const thumbnails = new Splide(sliderThumbs, {
                    type: 'loop',
                    arrows: false,
                    autoplay: false,
                    pagination: false,
                    perPage : 6,
                    gap: 30,
                    // focus: 'center', // in case we want the first element focused instead of the one in the middle
                    breakpoints: {
                        500: {
                            type: 'slide',
                            perPage : 3,
                            padding: 20,
                            gap: 20,
                        },
                        767: {
                            type: 'slide',
                            perPage : 4,
                            padding: 20,
                            gap: 20,
                        },
                        1023: {
                            perPage : 5,
                            type: 'slide',
                            gap: 20,
                            padding: 20,
                        },
                    },
                });

                // Mount both sliders.
                main.mount();
                thumbnails.mount();

                const thumbSlides = thumbnails.Components.Slides;
                thumbnails.on('click', function (clicked)  {
                    // Removes the active state in all of the thumbs.
                    thumbSlides.forEach((thumbnail) => {
                        const slide = thumbnail.slide;
                        slide.classList.remove('selected');
                    });
                    // Get the real clicked id.
                    let clickedSlideIndex = clicked.isClone ? clicked.slideIndex : clicked.index;
                    // Get clicked element clones.
                    const clones = thumbSlides.filter((thumb) => {
                        return thumb.isClone && thumb.slideIndex === clickedSlideIndex;
                    });
                    if(clones.length) {
                        // Add active state to clones.
                        clones.forEach((clone) => {
                            clone.slide.classList.add('selected');
                        });
                    }
                    // Get real clicked element.
                    const clickedSlide = thumbSlides.getAt(clickedSlideIndex);
                    // Add active state to real clicked element.
                    clickedSlide.slide.classList.add('selected');
                    // Navigate main slider to clicked index.
                    main.go(clickedSlideIndex);
                });

                main.on('moved', function (newIndex) {
                    const slide = main.Components.Slides.getAt(newIndex).slide;
                    const elementsToAnimate = slide.querySelectorAll('[data-slide-stagged-content-item]')
                    const imageToAnimate = slide.querySelector('[data-slide-image-opacity]');
                    let delayDif = 0.75 / elementsToAnimate.length;
                    const tl = gsap.timeline();
                    tl.add('start');

                    // Set initial state image
                    gsap.set(imageToAnimate, {
                        autoAlpha: 0
                    });

                    // Set initial state text
                    gsap.set(elementsToAnimate, {
                        y: 100,
                        autoAlpha: 0
                    });

                    // Gsap animation stagged text
                    elementsToAnimate.forEach((element, index) => {
                        tl.to(element, {
                            y: 0,
                            autoAlpha: 1,
                            duration: 0.75,
                            delay: delayDif * index
                        },'start');
                    });

                    // Gsap animation image opacity
                    gsap.to(imageToAnimate, {
                        autoAlpha: 1,
                        duration: 0.75
                    });
                });

                // Animation when entering in viewport
                const firstSlide = main.Components.Slides.getAt(0).slide;
                const firstSlideAnimatedImage = firstSlide.querySelector('[data-slide-image-opacity]');
                const firstSlideAnimatedContent = firstSlide.querySelectorAll('[data-slide-stagged-content-item]');

                const tl = gsap.timeline();
                tl.add('startFirstSlide');

                // Set initial state image
                gsap.set(firstSlideAnimatedImage, {
                    autoAlpha: 0
                });
                // Set initial state text
                gsap.set(firstSlideAnimatedContent, {
                    y: 100,
                    autoAlpha: 0
                });

                let delayDif = 0.75 / firstSlideAnimatedContent.length;

                ScrollTrigger.create({
                    trigger: sliderContent,
                    start: "top bottom-=20%",
                    end: 'top top',
                    scrub: 1,
                    invalidateOnRefresh: true,
                    toggleActions: 'play none none none',
                    once: true,
                    onEnter: function () {
                        // Gsap animation stagged text
                        firstSlideAnimatedContent.forEach((element, index) => {
                            tl.to(element, {
                                y: 0,
                                autoAlpha: 1,
                                duration: 0.75,
                                delay: delayDif * index
                            },'start');
                        });

                        // Gsap animation image opacity
                        gsap.to(firstSlideAnimatedImage, {
                            autoAlpha: 1,
                            duration: 0.75
                        });
                    }
                });
                
                // Custom next arrow logic.
                if(sliderNextButton) {
                    sliderNextButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        let { Components: { Controller } } = thumbnails;
                        Controller.go(Controller.getNext());
                    });
                }
            }
        })
    }
}
