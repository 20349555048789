export default function heroSliderAbout(Splide, AutoScroll) {
	const sliderWrappers =  document.querySelectorAll('[data-splide-slide]');
	
	if (sliderWrappers.length) {
		sliderWrappers.forEach((sliderWrapper) => {
			new Splide(sliderWrapper, {
				type: 'loop',
				drag: 'free',
				focus: 'center',
				arrows: false,
				pagination: false,
				perPage : 2,
				padding: '15%',
				gap: 30,
				
				autoScroll: {
					speed: 1,
					pauseOnHover: false,
				},

				breakpoints: {
                    767: {
						perPage : 1,
						gap: 20
                    },
                },
			}).mount({ AutoScroll });
		})
	}
}