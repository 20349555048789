/**
* Dependencies
*/
import 'focus-visible';
import './modules/scroll-buttons';
import './modules/overlay';
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable';

/**
* Aditional
*/
import asciiArt from './additional/ascii-art';
/**
* Animations
*/
import parallaxAnimations from './animations/parallax-animations';

/**
* Helpers
*/
import equalizerMultiple from './helpers/equalizer-multiple';
import paddingTopOnScroll from './helpers/spacing-on-scroll';

/**
 * Modules
 */
import menuNavigation from './modules/menu-navigation';
import heroSliderHome from './modules/hero-slider-home';
import heroSliderAbout from './modules/hero-slider-about';
import tabsGroup from './modules/tabs-group';
import teamMembers from './modules/team-members-slider';
import timelineSlider from './modules/timeline-slider';
import projectsSlider from './modules/projects-slider';
import heroSliderDouble from './modules/hero-slider-double';
// import dropdowns from './modules/dropdowns';
import optionsSelect from './modules/options-select';
import gsapAccordion from './modules/accordions';
import relatedPosts from './modules/related-posts-slider';
import portfolioFilters from './modules/portfolio-filters';
import cookieConsent from './modules/cookies-banner';
import map from './modules/google-map-script'
import tabsAboutSlider from './modules/tabs-about-slider';
import revealAnimations from './animations/reveal-animation';
import filtersReveal from './animations/filters-reveal';
import { iOS } from './helpers/get-device-type';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(Draggable);
gsap.registerPlugin(ScrollTrigger);

revealAnimations(gsap, (ScrollTrigger) => {
    parallaxAnimations(gsap, ScrollTrigger);
});

document.addEventListener('DOMContentLoaded', () => {
    menuNavigation();
    heroSliderHome(Splide, gsap, ScrollTrigger);
    heroSliderAbout(Splide, AutoScroll);
    equalizerMultiple();
    tabsGroup(gsap, ScrollTrigger);
    optionsSelect(gsap);
    teamMembers(Splide, gsap, ScrollTrigger);
    timelineSlider(Splide, gsap, Draggable);
    projectsSlider(Splide, gsap);
    heroSliderDouble(Splide);
    gsapAccordion(gsap);
    relatedPosts(Splide);
    portfolioFilters(gsap);
    cookieConsent();
    paddingTopOnScroll(gsap, ScrollTrigger);
    map();
    filtersReveal(gsap);
    tabsAboutSlider(Splide);
    // asciiArt();
    // dropdowns(gsap);
});

window.addEventListener('resize', () => {
    equalizerMultiple();

    // const isIOS = iOS();
    // if(!isIOS) {
    //     menuNavigation();
    // }
});