export default function map() {
	// api key
	// AIzaSyBP1uHsUdAMFXcNbWB6kmmD9czxulsMsjU
	const mapDom = document.getElementById("map");

	if(mapDom == null || mapDom == undefined){
		return
	}

	function initMap() {

		const lat = mapDom.getAttribute('data-lat');
		const lng = mapDom.getAttribute('data-lng');

		const markerAsset = mapDom.getAttribute('data-marker-asset')

		let zoom = 17;
		const mapCoordinates = { lat: Number(lat), lng: Number(lng) };

		console.log(mapCoordinates)

		// const zoomIn = document.querySelector('.button-zoom-in')
		// const zoomOut = document.querySelector('.button-zoom-out')

		const mapMarker = document.createElement('div');
		mapMarker.className = 'marker';
		mapMarker.textContent = 'Map Location'

		const map = new google.maps.Map(mapDom, {
			center: mapCoordinates,
			zoom: zoom,
			minZoom: 12,
			maxZoom: 20,
			//mapId: '6c302c8a54cb80b8',
			zoomControl: false,
			// zoomControlOptions: {
			//   position: google.maps.ControlPosition.TOP_RIGHT
			// },
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			styles: [
				{
					"featureType": "all",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"saturation": 36
						},
						{
							"color": "#333333"
						},
						{
							"lightness": 40
						}
					]
				},
				{
					"featureType": "all",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#ffffff"
						},
						{
							"lightness": 16
						}
					]
				},
				{
					"featureType": "all",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#fefefe"
						},
						{
							"lightness": 20
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#fefefe"
						},
						{
							"lightness": 17
						},
						{
							"weight": 1.2
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 20
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#eeede9"
						}
					]
				},
				{
					"featureType": "landscape.natural",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"hue": "#ff0000"
						}
					]
				},
				{
					"featureType": "landscape.natural.terrain",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#82afc2"
						}
					]
				},
				{
					"featureType": "landscape.natural.terrain",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#82afc2"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#dbd8d2"
						},
						{
							"lightness": 21
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#c8c5be"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#c8c5be"
						},
						{
							"lightness": 17
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c8c5be"
						},
						{
							"lightness": 29
						},
						{
							"weight": 0.2
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#dedede"
						},
						{
							"lightness": 18
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#c8c5be"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 16
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#c8c5be"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f2f2f2"
						},
						{
							"lightness": 19
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#ff0000"
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#a0d6d1"
						},
						{
							"lightness": 17
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"hue": "#00b3ff"
						}
					]
				}
			]
		});


		const markerView = new google.maps.Marker({
			position: mapCoordinates,
			map: map,
			icon: { url: markerAsset }
		});

		

		// zoomIn.addEventListener("click", () => {
		// 	map.setZoom(map.zoom + 1);
		// });

		// zoomOut.addEventListener("click", () => {
		// 	map.setZoom(map.zoom - 1);
		// });
	}

	initMap();
}
