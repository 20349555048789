export default function portfolioFilters (gsap){
	const portfolioFilters = document.querySelector('#portfolioFilters')
	var historySupport = !!(window.history && history.pushState)

	if(portfolioFilters){

		const filterTypes = portfolioFilters.querySelectorAll('.toggle-filter-options')
		const sortByBanner = document.querySelector('.sort-by-banner')
		const bannerResults = document.querySelector('.sort-by-banner-results')
		const tagsContent = document.querySelector('.tags-content')
		const urlParams = new URLSearchParams(window.location.search)
		const sortBy = urlParams.get('sort-by')

		function triggerFilters() {

			filterTypes.forEach(filterType => {
				filterType.addEventListener('click', () =>{	
					sortByBanner.classList.add('active')
					// Toggle Class on main Filter Type Selected

					if ( sortByBanner.classList.contains('active')){
						let filterTypesActive = portfolioFilters.querySelectorAll('.toggle-filter-options.showing-options')
						filterTypesActive.forEach(filterTypeActive => {
							if (filterTypeActive.classList.contains('showing-options')){
								filterTypeActive.classList.remove('showing-options')
							}
						})
						filterType.classList.toggle('showing-options')
						// Get and Shop Options for Selected Filter 
						let filterTypeId = filterType.getAttribute('id')
						//console.log(filterTypeId)
						let filterOptions = document.getElementById(filterTypeId + 'Options')
						//console.log(filterOptions)
						let filterOptionsActive = portfolioFilters.querySelectorAll('.filter-type-options.active')
						filterOptionsActive.forEach(optionsActive => {
							if (optionsActive.classList.contains('active')){
								optionsActive.classList.remove('active')
							}
						})
						filterOptions.classList.toggle('active')

						// Filter Type Option Selected Active Funtion
						getFilterOption()
					}


				})


				if(sortBy){
					if (sortBy.includes('status')){
						sortByBanner.classList.add('active')
						const filterTypeStatus = document.getElementById('projectFilterStatus')
						filterTypeStatus.classList.add('showing-options')
					}	

					if (sortBy.includes('project-type')){
						sortByBanner.classList.add('active')
						const filterProjectTypeStatus = document.getElementById('projectFilterType')
						filterProjectTypeStatus.classList.add('showing-options')
					}	

					if (sortBy.includes('state')){
						sortByBanner.classList.add('active')
						const filterTypeState = document.getElementById('projectFilterState')
						filterTypeState.classList.add('showing-options')
					}	
									
				}


				
			});			
		}

		function getFilterOption() {
			// Filter Type > Options Toggle Active Class
			const allOptions = portfolioFilters.querySelectorAll('.filter-type-options > div')
			allOptions.forEach(option => {
				option.addEventListener('click', () =>{
					let optionsActive = portfolioFilters.querySelectorAll('.filter-type-options > div.filter-active')
					optionsActive.forEach(optionActive => {
						if (optionActive.classList.contains('filter-active')){
							optionActive.classList.remove('filter-active')
						}
					})
					option.classList.toggle('filter-active')
					let filterActive = portfolioFilters.querySelector('.filter-active')
					//console.log(filterActive)
					let dataTagShowig = filterActive.getAttribute('data-tag-showing')
				
					//const sortByUrl = urlParams.get('sort-by');
					// Push URL
					if (historySupport) {
						history.pushState(null, null, `?sort-by=${dataTagShowig}`)
					}
					// Results Banner
					//console.log(bannerResults)
					bannerResults.classList.add('showing')
					tagsContent.innerHTML = (filterActive.outerHTML)
					// Cards Results Function
					sortByUrl()
				})
			})
		}

		triggerFilters()

		document.addEventListener('click', () =>{
			let filterTagActive = document.querySelector('.sort-by-banner-results.showing .filter-active')
			//console.log(filterTagActive)
			if(filterTagActive){
				//filterTagActive.addEventListener("click", triggerFilters, false);	
				filterTagActive.addEventListener('click', () =>{
					let filterActive = portfolioFilters.querySelector('.filter-active')
					let filterTypeOptionsActive = portfolioFilters.querySelector('.filter-type-options.active')
					filterActive.classList.remove('filter-active')
					filterTypeOptionsActive.classList.remove('active')
					bannerResults.classList.remove('showing')
					//console.log('remove sort by filters')
					const cardItem = document.getElementsByClassName('portfolio-card')
					gsap.to(cardItem, 0.05, {opacity: "1" })
					gsap.to(cardItem, 0.01, {display:"block"})

					let url = `${location.pathname}`;

					if (historySupport) {
						// Delete the foo parameter.
						history.pushState(null, null, url);
					}
					
				})
			}
		})
		
		function sortByUrl() {
			let tagsShowing = document.querySelectorAll('.tags-content > .filter-active')
			var filtersActive = [];
			tagsShowing.forEach((tag) =>  {
				filtersActive.push(tag.getAttribute('data-tag-showing'));
			})
	
			// Project Cards Results
			function filterProjectCards() {
				
				const urlContains = window.location.href
				//const sortByFromUrl = urlParams.get('sort-by')
				
				// let dataProjectType = document.querySelectorAll('[data-project-type]')
				// let dataProjectStatus = document.querySelectorAll('[data-project-status]')
				// let dataProjectState = document.querySelectorAll('[data-project-state]')

				let dataProjects = document.querySelectorAll('[data-project-type], [data-project-status], [data-project-state]')

				dataProjects.forEach((card) => {
					let cardType = card.getAttribute('data-project-type')
					let cardStatus = card.getAttribute('data-project-status')
					let cardState = card.getAttribute('data-project-state') 
					//console.log( cardType )
					if(urlContains.includes('sort-by')){
						if (urlContains.indexOf(cardType) > -1){
							card.classList.add('is-showing')
							gsap.to(card, 0.05, {opacity: "1" })
							gsap.to(card,  0.01, {display:"block"})
						}

						else if (urlContains.indexOf(cardStatus) > -1){
							card.classList.add('is-showing')
							gsap.to(card, 0.05, {opacity: "1" })
							gsap.to(card,  0.01, {display:"block"})
						}

						else if (urlContains.indexOf(cardState) > -1){
							card.classList.add('is-showing')
							gsap.to(card, 0.05, {opacity: "1" })
							gsap.to(card,  0.01, {display:"block"})
						}
						
						else if (urlContains.indexOf('all-properties') > -1) {
							card.classList.add('is-showing')
							gsap.to(card, 0.05, {opacity: "1" })
							gsap.to(card,  0.01, {display:"block"})	
						}
						else{
							card.classList.remove('is-showing')
							gsap.to(card, 0.05, {opacity: "0" })
							gsap.to(card, 0.01, {display:"none"})
							//card.remove()
						}							
					}
			
				})

				// Showing Counter Results
				let countCards = document.querySelectorAll('.is-showing').length

				counterProperties.textContent = (countCards)
				//console.log(countCards)


			}

			filterProjectCards()

		}
		// Filter On URL sort-by
		const urlContains = window.location.href
		if(urlContains.includes('sort-by')){
			const filter = urlParams.get('sort-by')
			let filterActive = document.querySelector('.filter-type-options > [data-tag-showing='+'"'+ filter +'"]')
			filterActive.classList.add('filter-active')
			filterActive.parentNode.classList.add('active')
			//console.log(filterActive.parentNode);
			tagsContent.innerHTML = (filterActive.outerHTML)
			bannerResults.classList.add('showing')
			getFilterOption()
			sortByUrl()
		}


	} // end portfolioFilters
}