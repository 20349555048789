import { gsap } from 'gsap';
import { getDeviceType, iOS } from '../helpers/get-device-type';

export default function menuNavigation(){

    const header = document.querySelector('header.main-header');
    const navigation = header.querySelector('.main-navigation')
    const body = document.querySelector('body')
    const touchEvent = 'touchstart' in window ? 'touchstart' : 'click';

    const isIOS = iOS();
    if(isIOS) {
        body.classList.add('is-iphone');
    }

    if (navigation){
        const navigationCurtainsIn = navigation.querySelector('.main-navigation-curtains.curtains-in');
        const navigationCurtainsOut = navigation.querySelector('.main-navigation-curtains.curtains-out');
        const curtainsIn = navigationCurtainsIn.querySelectorAll('div');
        const curtainsOut = navigationCurtainsOut.querySelectorAll('div');
        const logoLarge = navigation.querySelector('.logo-text');
        const navItems = navigation.querySelectorAll('.navigation-container .nav-items li');
        const navInfo = navigation.querySelectorAll('.info-items-desktop, .info-items-mobile');
        const navTrigger = document.querySelector('.nav-trigger');

        //**** Focus trap elements - accessibility
        // Get device type to get correct links (some links hidden in mobile/desktop)
        let deviceType = getDeviceType(); 
        // Get the main links array
        const mainLinks = navigation.querySelectorAll('ul.nav-items li a'); 
        // Get the secondary links array (according to device type (mobile/tablet-desktop))
        const secondaryLinks = deviceType === 'mobile' ? navigation.querySelectorAll('.info-items-mobile a') : navigation.querySelectorAll('.info-items-desktop a');
        // Merge main and secondary links arrays.
        const focusInternalAnchors = [...Array.from(mainLinks), ...Array.from(secondaryLinks)];
        // Get first focus trap div.
        const focusTrapFirst = header.querySelector('[data-focus-trap-first]');
        // Get last focus trap div.
        const focusTrapLast = header.querySelector('[data-focus-trap-last]');

        // Make all links not focusable when page loads (menu is closed).
        toggleTabIndex(focusInternalAnchors, false);

        let focusLastChildAnchor = null;
		let focusFirstChildAnchor = null;

		if (focusInternalAnchors.length) {
            // Get first link in menu from merged focusInternalAnchors.
            focusFirstChildAnchor = [...focusInternalAnchors].shift();
            // Get last link in menu from merged focusInternalAnchors.
            focusLastChildAnchor = [...focusInternalAnchors].pop();
			if (focusLastChildAnchor) {
                // When last link is focused, last focus trap tabindex to 0 - focusable
				focusLastChildAnchor.addEventListener('focus', () => {
					focusTrapLast.setAttribute('tabindex', 0);
				});
			}
			if (focusFirstChildAnchor) {
                // When first link is focused, first focus trap tabindex to 0 - focusable
				focusFirstChildAnchor.addEventListener('focus', () => {
					focusTrapFirst.setAttribute('tabindex', 0);
				});
			}
		}

        const tl = gsap.timeline({
            paused: true
        });
        tl.add('navAnimation');
        //Internal Elements
        //Logo
        tl.to(logoLarge, {
            y: 0,
            delay: 0.9,
            duration: 0.75
        }, 'navAnimation');
        //List
        tl.to(navItems, {
            x: 0,
            autoAlpha: 1,
            stagger: 0.2,
            duration: 0.45,
            delay: 0.9,
        }, 'navAnimation');
        //Address Info
        tl.to(navInfo, {
            y: 0,
            autoAlpha: 1,
            stagger: 0.2,
            duration: 0.65,
            delay: 0.9,
        }, 'navAnimation');

        navTrigger.addEventListener(touchEvent, (e) => {
            body.classList.toggle('nav-opened');
            if(body.classList.contains('nav-opened')) { // Should open menu
                openMenu(body, navigation, curtainsIn, tl);
                //*** Focus trap accessibility attributes
                // Make all links focusable (menu is open)
                toggleTabIndex(focusInternalAnchors, true);
                // Set burger button accessibility attributes
				navTrigger.setAttribute('aria-expanded', true);
                // Allows last trap to be focusable
                focusTrapLast.setAttribute('tabindex', 0);
            } else { // Should close menu
                document.documentElement.scrollTop = 0;
                closeMenu(body, navigationCurtainsOut, curtainsOut, curtainsIn, navigation, tl);
                //*** Focus trap accessibility attributes
                // Make all links not-focusable (menu is closed)
                toggleTabIndex(focusInternalAnchors, false);
                // Set burger button accessibility attributes
                navTrigger.setAttribute('aria-expanded', false);
                // Prevents last trap to be focusable
                focusTrapLast.setAttribute('tabindex', -1);
            }
        });

        //**** Focus trap elements - accessibility
        // When last focus trap is focused, move focus to the burger button.
        focusTrapLast.addEventListener('focus', () => {
            navTrigger.focus();
            focusTrapLast.setAttribute('tabindex', -1);
        });
        // When first focus trap is focused, move focus to the last menu link.
        focusTrapFirst.addEventListener('focus', () => {
            focusLastChildAnchor.focus();
            focusTrapFirst.setAttribute('tabindex', -1);
        });

        // ESC press close menu
        document.addEventListener('keydown', evt => {
            if (evt.key === 'Escape' && body.classList.contains('nav-opened')) {
                body.classList.remove('nav-opened');
                document.documentElement.scrollTop = 0;
                closeMenu(body, navigationCurtainsOut, curtainsOut, curtainsIn, navigation, tl);
                //*** Focus trap accessibility attributes
                // Make all links not-focusable (menu is closed)
                toggleTabIndex(focusInternalAnchors, false);
                // Set burger button accessibility attributes
                navTrigger.setAttribute('aria-expanded', false);
				navTrigger.setAttribute('aria-hidden', true);
                // Prevents last trap to be focusable
                focusTrapLast.setAttribute('tabindex', -1);
            }
        });

        // anchors.forEach((anchor) => {
        //     const href = anchor.href;
        //     if(href && href !== '#') {
        //         anchor.addEventListener(touchEvent, (e) => {
        //             e.preventDefault();
        //             tl.reverse();
        //             setTimeout(() => {
        //                 window.location.href = href;
        //             }, 1500);
        //         });
        //     }
        // });
    }
}

function openMenu(body, navigation, curtainsIn, tl) {
    gsap.to(navigation, {
        y: 0,
        delay: 0,
        duration: 0.45,
        onStart: () => {
            body.classList.add('nav-opened-white-bg');
        }
    });
    gsap.to(curtainsIn, {
        y: '100%',
        stagger: 0.2,
        duration: 0.75,
        delay: 0.2
    });
    tl.play();
}

function closeMenu(body, navigationCurtainsOut, curtainsOut, curtainsIn, navigation, tl) {
    gsap.set(navigationCurtainsOut, {
        css: {
            zIndex: 1
        }
    });
    gsap.to(curtainsOut, {
        y: 0,
        stagger: 0.2,
        duration: 0.4,
        delay: 0,
        ease: 'none',
        onComplete: () => {
            body.classList.remove('nav-opened-white-bg');
            gsap.to(navigation, {
                y: '100%',
                // delay: 0.85,
                duration: 0.2,
                ease: 'none',
                onComplete: () => {
                    tl.restart()
                    tl.pause();
                    gsap.set(curtainsOut, {
                        y: '-100%'
                    });
                    gsap.set(curtainsIn, {
                        y: 0
                    });
                    gsap.set(navigation, {
                        y: '-150%'
                    });
                    gsap.set(navigationCurtainsOut, {
                        css: {
                            zIndex: -1
                        }
                    });
                }
            });
        }
    });
}

function toggleTabIndex(links, focusable) {
    links.forEach((link) => {
        if (focusable ? link.removeAttribute('tabIndex') : link.setAttribute('tabindex', -1));
    });
}