//@todo:☝🏼 accessibility functionality.
export default function tabsGroup(gsap, ScrollTrigger) {
    const tabSections =  document.querySelectorAll('[data-has-tabs]');
    
    if(tabSections.length) {
        tabSections.forEach((tabSection) => {
            const buttonsTab = tabSection.querySelectorAll('[data-tabs-button-target]');
            const tabsContainer = tabSection.querySelector('[data-tabs-container]');
            if(buttonsTab.length){
                const animatedElements = tabSection.querySelectorAll('[data-animated]');
                gsap.set(animatedElements, {
                    autoAlpha: 0
                });

                ScrollTrigger.create({
                    trigger: tabSection,
                    start: "top bottom-=40%",
                    end: 'top top',
                    scrub: 1,
                    invalidateOnRefresh: true,
                    toggleActions: 'play none none none',
                    once: true,
                    onEnter: function () {
                        gsap.to(animatedElements, {
                            autoAlpha: 1,
                            stagger: 0.2,
                            duration: 0.75,
                            delay: 0.5
                        });
                    }
                });

                buttonsTab.forEach((buttonTab) => {
                    buttonTab.addEventListener('click', (e) => {
                        e.preventDefault(); 
                        const targetTab = tabsContainer.querySelector(`#${buttonTab.dataset.tabsButtonTarget}`);
                        if(targetTab){
                            const targetTabAnimatedElements = targetTab.querySelectorAll('[data-animated]');
                            resetTabs(buttonsTab, tabsContainer, animatedElements, gsap);
                            targetTab.classList.add('active');
                            buttonTab.classList.add('active');
                            buttonTab.setAttribute('aria-selected', true);
                            gsap.to(targetTabAnimatedElements, {
                                autoAlpha: 1,
                                stagger: 0.2,
                                duration: 0.75,
                                delay: 0.2
                            });
                        }
                    });
                })
            }
        });
    }
}

function resetTabs(buttons, tabsContainer, animatedElements, gsap) {
    const tabsContent = tabsContainer.querySelectorAll('.tabs-tabpanel');
    tabsContent.forEach((tabContent) => {
        tabContent.classList.remove('active');
    });
    buttons.forEach((button) => {
        button.classList.remove('active');
        button.setAttribute('aria-selected', false);
    });
    gsap.set(animatedElements, {
        autoAlpha: 0
    });
}