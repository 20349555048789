export default function heroSliderHome(Splide, gsap) {
	const sliderHomeSection = document.querySelector('.hero-slider-home');
	if (sliderHomeSection) {
		const sliderHome = sliderHomeSection.querySelector('.splide-home');
		const sliderCounters = sliderHomeSection.querySelectorAll('.slide-arrows-content .current-slide-number');
		const sliderButtons = sliderHomeSection.querySelectorAll('.slide-arrows-content button');

		// let countSlides = sliderHome.querySelectorAll('.splide__slide').length
		// slidesCounter.innerHTML = countSlides

		const splide = new Splide(sliderHome, {
			type: 'fade',
			arrows: false,
			rewind: true,
			perPage: 1,
			pagination: false,
			autoplay: false
		});

		splide.on('mounted', function (e) {
			const slide = splide.Components.Slides.getAt(0).slide;
			const animatedElements = slide.querySelectorAll('[data-slide-animated] > div');
			const animatedContents = slide.querySelectorAll('[data-slide-animated-content] > *');
			animateToK(gsap, animatedElements);
			const tl = gsap.timeline();
			tl.add('start');
			animateContentStagger(tl, animatedContents);
		});

		splide.mount();

		splide.on('move', function (e) {
			updateCounter(sliderCounters, (e + 1));
			const slide = splide.Components.Slides.getAt(e).slide;
			const animatedElements = slide.querySelectorAll('[data-slide-animated] div');
			const animatedContent = slide.querySelectorAll('[data-slide-animated-content] > *');
			gsap.set(animatedElements, {
                x: 0,
            });
			gsap.set(animatedContent, {
                y: 100,
				autoAlpha: 0,
            });
		});

		splide.on('moved', function (newIndex, prevIndex, destIndex) {
			const slide = splide.Components.Slides.getAt(newIndex).slide;
			const animatedElements = slide.querySelectorAll('[data-slide-animated] > div');
			const animatedContents = slide.querySelectorAll('[data-slide-animated-content] > *');
			animateToK(gsap, animatedElements);
			const tl = gsap.timeline();
			tl.add('start');
			animateContentStagger(tl, animatedContents);
			// animatedElements.forEach((elem) => {
			// 	// const animationType = elem.dataset.slideAnimated;	
			// 	animateTo(gsap, elem);
			// });
			// animateTo(gsap, animatedContent, 'content');
		});

		// Custom arrows logic.
		if(sliderButtons.length) {
			sliderButtons.forEach((sliderButton) => {
				sliderButton.addEventListener('click', (e) => {
					e.preventDefault();
					let { Components: { Controller } } = splide;
					if(sliderButton.classList.contains('btn-circle-arrow-slider-next')) {
						Controller.go(Controller.getNext());
					} else {
						Controller.go(Controller.getPrev());
					}
				})
			});
		}
	}
}

const animateToK = (gsap, elem) => {
	gsap.to(elem, {
		x: '-100%',
		stagger: 0.3,
		duration: 1.6,
		delay: 0
	});
	// if(type === 'curtains') {
	// 	const curtains = elem.querySelectorAll('div');

	// } else {
	// 	gsap.to(elem, {
	// 		y: 0,
	// 		autoAlpha: 1,
	// 		stagger: 0.3,
	// 		duration: 0.5,
	// 		delay: 0
	// 	});
	// }
	// if(animationType === 'vertical-bottom-to-top') {
	// 	gsap.to(curtains, {
	// 		y: '-100%',
	// 		stagger: 0.3,
	// 		duration: 0.75,
	// 		delay: 0
	// 	});
	// } else {
	// 	gsap.to(curtains, {
	// 		y: '100%',
	// 		stagger: 0.3,
	// 		duration: 0.75,
	// 		delay: 0
	// 	});
	// }
}

const animateContentStagger = (tl, animatedContents) => {
	let delayDif = 0.25 / animatedContents.length;
	animatedContents.forEach((element, index) => {
		tl.to(element, {
			y: 0,
			autoAlpha: 1,
			duration: 0.25,
			delay: delayDif * index
		}, 'start');
	});
}

const updateCounter = (sliderCounters, page) => {
	if(sliderCounters.length) {
		sliderCounters.forEach((counter) => {
			counter.textContent = page; 
		})
	}
}