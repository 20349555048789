export default function projectsSlider(Splide, gsap){
	const projectsSectionSlider =  document.querySelector('.projects-slider');

	if (projectsSectionSlider){
		const indicator = document.querySelector('.indicator')
		//const slides = document.querySelectorAll('.splide__slide')
		const navItems = document.querySelectorAll('.items div')
		// console.log(navItems);
		const projectLinkImgitems = document.querySelectorAll('.project-link-img')
		const viewProjectButton = document.querySelector('#viewProjectButton')
		let currentSlide = 0
		
		// Indicator Init Position
		let firstMove = navItems[0].offsetLeft
		let firstOffset = navItems[0].offsetWidth / 2
		gsap.set(indicator, {left: firstMove + firstOffset})

		const splide = new Splide( projectsSectionSlider, {
			perPage : 	1,
			type    :	'loop',
		} );

		projectLinkImgitems.forEach((linkImgItem) => {
			linkImgItem.addEventListener('mouseover', () => {
				viewProjectButton.classList.add('hovered-state-before')
			})
			linkImgItem.addEventListener('mouseleave', () => {
				viewProjectButton.classList.remove('hovered-state-before')
			})
		});	

		function dataCurrentProjectSlide() {
			const viewProjectButton = document.getElementById('viewProjectButton')
			const projectLinkMobile = document.getElementById('projectLinkMobile')

			let projectActiveVisibleLink = document.querySelector('.projects-slider .splide__slide.is-active.is-visible .js-project-info-content .project-info-content a');
			let projectActiveVisibleTitle = document.querySelector('.projects-slider .splide__slide.is-active.is-visible .js-project-info-content .project-info-content .project-title').textContent;
			let projectActiveVisibleProjectLink = projectActiveVisibleLink.getAttribute('href')

			viewProjectButton.setAttribute('href', projectActiveVisibleProjectLink)
			projectLinkMobile.setAttribute('href', projectActiveVisibleProjectLink)
			projectLinkMobile.textContent = (projectActiveVisibleTitle)
		}

		splide.on('ready', function (e) {
			dataCurrentProjectSlide()
		});

		//move: Moves the carousel to the dest index with the Transition component
		//move: Fired right before the carousel moves.

		splide.on('move', function (e) {
			// Indicator Slip on Change Slide
			const move = navItems[e].offsetLeft
			// console.log(e);
			const offset = navItems[e].offsetWidth / 2
			gsap.to(indicator, {left: (move + offset )})
			//indicator.style.left = (move + offset )
			currentSlide = e;	

			// Animation Zoomin Image
			const slide = splide.Components.Slides.getAt(e).slide
			const zoomImage = slide.querySelector('img');
			gsap.set(zoomImage, {
				scale: 1.2
			});	
		} );

		splide.mount();

		//moved Fired right after the carousel moves.

		splide.on('moved', function (newIndex, prevIndex, destIndex)  {
			// View Project Button Link and Info Current Slide Indicator
			dataCurrentProjectSlide()
			// Animation Zoomin Image
			const slide = splide.Components.Slides.getAt(newIndex).slide;
			const zoomImage = slide.querySelector('img');
            gsap.to(zoomImage, {
                scale: 1,
                duration: 2.5
            });
		});
		
		var projectPaginationButtons = document.querySelectorAll('.projects-slider .splide__pagination > li')

		if(projectPaginationButtons){
			projectPaginationButtons.forEach((paginationItem) => {
				var projectSlides = document.querySelectorAll('.projects-slider .splide__slide');
				var thisItemControlClass = paginationItem.firstElementChild.getAttribute('aria-controls')
				// Add class by aria-controls id
				paginationItem.classList.add(thisItemControlClass)
				// Get project content slide by items id's
				projectSlides.forEach((slideItemClass) => {
					var slideItemClass = slideItemClass.getAttribute('id')
					var slideItemContents = document.querySelector('#' + slideItemClass + '> .js-project-info-content')
					slideItemContents.classList.add(slideItemClass)
				});
				// Add .project-info-content into pagination
				let thisItemContent = document.querySelector('.js-project-info-content.' + thisItemControlClass )
				paginationItem.firstElementChild.innerHTML+= (thisItemContent.innerHTML)
			});	
		}
	}
}