export default function tabsAboutSlider(Splide) {
	const tabAboutSliders = document.querySelectorAll('.tabs-about-slider');
    if(tabAboutSliders.length) {
        tabAboutSliders.forEach((tabAboutSlider, index) => {
            const sliderButtons = tabAboutSlider.querySelectorAll('.tabs-about-slider-button button');
            const slide = tabAboutSlider.querySelector('.tabs-about-splide');
            const splide = new Splide(slide, {
                arrows: false,
                perPage: 1,
                perMove: 1,
                pagination: false,
                autoplay: true,
                interval: 4000
            });

            splide.mount();

            // Custom arrows logic.
            if(sliderButtons.length) {
                sliderButtons.forEach((sliderButton) => {
                    sliderButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        let { Components: { Controller } } = splide;
                        if(sliderButton.classList.contains('btn-arrow-slider-next')) {
                            Controller.go(Controller.getNext());
                        } else {
                            Controller.go(Controller.getPrev());
                        }
                    })
                });
            }
        });
    }
}