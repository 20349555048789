import { getDeviceType } from '../helpers/get-device-type';

export default function timelineSlider(Splide, gsap, Draggable) {
    const sliderTimelineSections = document.querySelectorAll('[data-timeline-slide]');
    if (sliderTimelineSections.length) {
        sliderTimelineSections.forEach((sliderTimelineSection) => {
            const sliderWrapper = document.querySelector('[data-splide-timeline-slide]');
            const buttonsWrapper = sliderTimelineSection.querySelector('.hero-timeline-slider-buttons');
            const draggableButton = sliderTimelineSection.querySelector('[data-splide-buttons]');

            const splide = new Splide(sliderWrapper, {
                type: 'loop',
                arrows: false,
                autoplay: false,
                pagination: false,
                perPage: 1,
                padding: '25%',
                gap: 0,

                breakpoints: {
                    1023: {
                        destroy: true,
                    }
                },
            });

            splide.on('resize', () => {
                const lineSeparator = sliderWrapper.querySelector('.hero-timeline-slider-buttons-line');
                const sliderContent = sliderTimelineSection.querySelector('.hero-timeline-slider-content');
                let deviceType = getDeviceType();
                let borderTop = deviceType === 'desktop' ? 75 : 0;
                let buttonsTopPosition = positionArrows(sliderContent, lineSeparator);
                buttonsWrapper.style.top = `${buttonsTopPosition - borderTop}px`;
            });

            splide.mount();

            let startX;
            let splidePlay = null;
            let dragDirection = '';

            Draggable.create(draggableButton, {
                bounds: draggableButton.parentNode,
                edgeResistance: 0,
                type: 'x',
                throwProps: true,
                onPress: function() {
                    gsap.to(this.target, {scale: 0.7, duration: 0.5});
                },
                onDragStart: function() {
                    // record the starting X value so we can compare it when dragging.
                    startX = this.x;
                },
                onDrag: function() {
                    let xChange = this.x - startX;
                    let currentDirection = (xChange < 0) ? 'left' : 'right';
                    if(dragDirection !== currentDirection) {
                        dragDirection = currentDirection;
                        clearInterval(splidePlay);
                        splidePlay = null;
                        if(dragDirection === 'right') {
                            splide.go('>');
                            splidePlay = setInterval(() => {
                                splide.go('>');
                            }, 1000);
                        } else {
                            splide.go('<');
                            splidePlay = setInterval(() => {
                                splide.go('<');
                            }, 1000);
                        }
                    }
                },
                onRelease: function() {
                    gsap.to(this.target, { x: 0 , y: 0, scale: 1, duration: 0.5 });
                    dragDirection = '';
                    clearInterval(splidePlay);
                    splidePlay = null;
                }
            });
        });
    }
}

function positionArrows(sliderContent, lineSeparator) {
    const sliderContentRect = sliderContent.getBoundingClientRect();
    const lineSeparatorRect = lineSeparator.getBoundingClientRect();
    return lineSeparatorRect.top - sliderContentRect.top;
}