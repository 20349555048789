/**
 * Creates a re-usable parallax code.
 * 
 * Example parallax (triggers when element is about 20% off the screen).
 * The percentage of the screen scroll trigger can be modified using dataset:
 *     data-parallax-animated-scroll-start="10%"
 * 
 * Start value can be set using: data-parallax-animated-start-value="-100"
 * 
 * <section>
 *  <div>
 *    <!-- your awesome animated content here -->
 *    <span data-parallax-animated="line-from-left"></span>
 *  </div>
 * </section>
 * 
 * Available values:
 * - line-from-left
 * - line-from-right
 * - line-from-top
 * - line-from-bottom
 * - reveal-from-below
 * - opacity
 * - slide-from-left
 * - slide-from-right
 * - slide-from-top
 * - slide-from-bottom
 * 
 * //////////////
 * 
 * Example parallax with scroll animation (triggers when element's parent is on the screen):
 * <section>
 *  <div>
 *    <!-- your awesome animated with scroll content here -->
 *    <div data-parallax-scroll="vertical" data-parallax-scroll-value="-600"></div>
 *  </div>
 * </section>
 * 
 * Available values:
 * - vertical
 * - horizontal
 * @default: "horizontal"
 * 
 * data-parallax-scroll-value is the value for the animated translation (x or y). @default: 400
 * 
 * //////////////
 * 
 * Example to show elements one after the prev.
 * <section data-parallax-stagger>
 *   <div data-parallax-stagger-item>...</div>
 *   <div data-parallax-stagger-item>...</div>
 *   <div data-parallax-stagger-item>...</div>
 * </section>
 * 
 * //////////////
 * 
 * Example arrow-stagger (The arrows move horizontally giving the impression of each one appearing)
 * Styles: @mixin animated-hero-arrows();
 * <section>
 *  <div data-arrow-stagger>
 *     <span class="large-arrow"></span> 
 *     <span class="small-arrow-1"></span
 *     <span class="small-arrow-2"></span>
 *     <span class="small-arrow-3"></span>
 *   </div>   
 * </section>
 * 
 * * //////////////
 * 
 * Example animatedTilt data-animation-tilt
 * 
 *  * * //////////////
 * 
 * Example animatedCurtain data-parallax-curtain
 * 
 *  * * //////////////
 * 
 * Example Parallax animatedBlocksMultiple
 * 
 *  * * //////////////
 * 
 * Example Animate multiple elements with stagged animatedStaggedContent
 * 
 * *  * * //////////////
 * 
 * Example Animate elements that contains redactor cms integration animatedStaggedRedactor
 * 
 * *  * * //////////////
 */

export default function parallaxAnimations(gsap) {

    const animatedElements = document.querySelectorAll('[data-parallax-animated]');
    const animatedElementsScroll = document.querySelectorAll('[data-parallax-scroll]');
    const animatedElementsStagger = document.querySelectorAll('[data-parallax-stagger]');
    const animatedArrowStagger = document.querySelectorAll('[data-arrow-stagger]');
    const animatedTilt = document.querySelectorAll('[data-animation-tilt]');
    const animatedCurtain = document.querySelectorAll('[data-parallax-curtain]');
    const animatedBlocksMultiple = document.querySelectorAll('[data-parallax-multiple]');
    const animatedStaggedContent = document.querySelectorAll('[data-parallax-stagged-content]');
    const animatedStaggedRedactor = document.querySelectorAll('[data-parallax-stagged-redactor]');

    // Animate elements with mouse wheel (on scroll).
    if (animatedElementsScroll.length) {
        animatedElementsScroll.forEach((element)=>{
            const parent = element.parentNode;
            const parallaxDirection = element.dataset.parallaxScroll || 'horizontal';
            const parallaxValue = element.dataset.parallaxScrollValue || 400;
            if(parallaxDirection === 'horizontal') {
                gsap.to(element, {
                    ease:'none',
                    x: parallaxValue,
                    scrollTrigger: {
                        trigger: parent,
                        start: "top bottom",
                        end: 'bottom top',
                        scrub: true,
                    }
                });
            } else {
                gsap.to(element, {
                    ease:'none',
                    y: parallaxValue,
                    scrollTrigger: {
                        trigger: parent,
                        start: "top bottom",
                        end: 'bottom top',
                        scrub: true,
                    }
                });
            }
        })
    }
    // Animate elements when they enter the viewport.
    if(animatedElements.length) {
        // Handles effects for single elements
        animatedElements.forEach((elem) => {
            const animationType = elem.dataset.parallaxAnimated;
            const animationPercentStart = elem.dataset.parallaxAnimatedScrollStart || '20%';
            const animationDelay = elem.dataset.parallaxAnimatedDelay || 0;
            
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: elem,
                    start: `top bottom-=${animationPercentStart}`,
                    end: 'top top',
                    toggleActions: 'play none none reverse'
                }
            });

            // Set initial state
            initialState(gsap, elem, animationType);

            animateToTl(tl, elem, animationType, animationDelay);
        });
    }
    // Animate elements when they enter the viewport.
    if(animatedTilt.length) {
        // Handles effects for single elements
        animatedTilt.forEach((elem) => {
            const animationPercentStart = elem.dataset.parallaxAnimatedScrollStart || '30%';            
            const tl = gsap.timeline({
                yoyo: true,
                scrollTrigger: {
                    trigger: elem,
                    start: `top bottom-=${animationPercentStart}`,
                    end: 'top top',
                    toggleActions: 'play none none reverse',
                }
            });

            tl.to(elem, {
                x: -100,
                duration: 0.7
            })
            tl.to(elem, {
                x: 100,
                duration: 0.7
            })
            tl.to(elem, {
                x: 0,
                duration: 0.35
            })
        });
    }
    // Animate a group of elements one after the previous one.
    if(animatedElementsStagger.length) {
        animatedElementsStagger.forEach((parentStagger) => {
            const animationPercentStart = parentStagger.dataset.parallaxAnimatedScrollStart || '40%'; 
            const staggerItems = parentStagger.querySelectorAll('[data-parallax-stagger-item]');
            if(staggerItems.length) {
                gsap.set(staggerItems, {
                    autoAlpha: 0,
                });
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: parentStagger,
                        start: `top bottom-=${animationPercentStart}`,
                        end: 'top top',
                        toggleActions: 'play none none reverse'
                    }
                });
                tl.to(staggerItems, {
                    autoAlpha: 1,
                    stagger: 0.1,
                    duration: 0.75,
                    delay: 0
                });
            }
        });
    }
    if(animatedArrowStagger.length) {
        animatedArrowStagger.forEach((arrowsStaggerContainer) => {
            const arrows = arrowsStaggerContainer.querySelectorAll('span');
            if(arrows.length){

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: arrowsStaggerContainer.parentNode,
                        start: "top bottom-=20%",
                        end: 'top top',
                        toggleActions: 'play none none reverse'
                    }
                });
                tl.to(arrows, {
                    x: 0,
                    stagger: 0.2,
                    duration: 0.75,
                    delay: 0
                });
            }
        });
    }
    // Double curtain animations
    if(animatedCurtain.length) {
        // Handles effects for single elements
        animatedCurtain.forEach((elem) => {
            const curtainType = elem.dataset.parallaxCurtain || 'horizontal';
            const curtains = elem.querySelectorAll('div');
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: elem,
                    start: "top bottom-=40%",
                    end: 'top top',
                    toggleActions: 'play none none reverse'
                }
            });
            if(curtainType === 'horizontal') {
                tl.to(curtains, {
                    x: '-100%',
                    stagger: 0.2,
                    duration: 0.5,
                    delay: 0
                });
            } else {
                tl.to(curtains, {
                    y: '-100%',
                    stagger: 0.2,
                    duration: 0.5,
                    delay: 0
                });
            }
        });
    }
    // Multiple animations in a block
    if(animatedBlocksMultiple.length) {
        animatedBlocksMultiple.forEach((block) => {
            const background = block.querySelector('[data-parallax-multiple-background]');
            const content = block.querySelectorAll('[data-parallax-multiple-content] > *');
            const curtainsParentImage = block.querySelectorAll('[data-parallax-multiple-image]');
            const curtains = block.querySelectorAll('[data-parallax-multiple-curtain] > div');
            gsap.set(background, {
                x: '100%'
            });
            gsap.set(curtainsParentImage, {
                x: '100%'
            });
            gsap.set(content, {
                y: 100,
                autoAlpha: 0
            });

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: block,
                    start: "top bottom-=50%",
                    end: 'top top',
                    toggleActions: 'play none none reverse'
                }
            });

            let delayDif = 0.75 / content.length;
            
            tl.add('startBlocksMultiple')
                .to(background, {
                    x: 0,
                    duration: 0.75,
                    delay: 0,
                }, 'startBlocksMultiple')
                .to(curtainsParentImage, {
                    x: 0,
                    duration: 0.5,
                    delay: 0.35
                }, 'startBlocksMultiple')
                .to(curtains, {
                    x: '-100%',
                    stagger: 0.2,
                    duration: 0.5,
                    delay: 0.5,
                }, 'startBlocksMultiple');

                content.forEach((element, index) => {
                    tl.to(element, {
                        y: 0,
                        autoAlpha: 1,
                        duration: 0.75,
                        delay: (delayDif * index) + 0.75
                    }, 'startBlocksMultiple');
                });


        });
    }
    // Stagged content overlap
    if(animatedStaggedContent.length) {
        animatedStaggedContent.forEach((parent) => {
            const duration = parseInt(parent.dataset.parallaxStaggedContentDuration) || 0.75;
            const animationPercentStart = parent.dataset.parallaxAnimatedScrollStart || '0';
            const children = parent.querySelectorAll('[data-parallax-stagged-content-item]');
            const tlContent = gsap.timeline({
                scrollTrigger: {
                    trigger: parent,
                    start: `top bottom-=${animationPercentStart}`,
                    end: 'top top',
                    toggleActions: 'play none none reverse'
                }
            });

            let delayDif = duration / children.length;
            children.forEach((element, index) => {
                // Set initial state
                initialState(gsap, element, 'reveal-from-below');
                tlContent.to(element, {
                    y: 0,
                    autoAlpha: 1,
                    duration: duration,
                    delay: delayDif * index
                }, 'start');
            });
        });
    }
    // Stagged elements that are created in a redactor
    if(animatedStaggedRedactor.length) {
        animatedStaggedRedactor.forEach((redactorWrapper) => {
            const duration = parseInt(redactorWrapper.dataset.parallaxStaggedContentDuration) || 0.75;
            const animationPercentStart = redactorWrapper.dataset.parallaxAnimatedScrollStart || '20%';
            const children = redactorWrapper.querySelectorAll(':scope > *');
            const tlContent = gsap.timeline({
                scrollTrigger: {
                    trigger: redactorWrapper,
                    start: `top bottom-=${animationPercentStart}`,
                    end: 'top top',
                    toggleActions: 'play none none reverse'
                }
            });

            let delayDif = duration / children.length;
            children.forEach((element, index) => {
                initialState(gsap, element, 'reveal-from-below');
                tlContent.to(element, {
                    y: 0,
                    autoAlpha: 1,
                    duration: duration,
                    delay: delayDif * index
                }, 'start');
            });
        });
    }
}

const initialState = (gsap, elem, animationType) => {
    switch (animationType) {
        case 'line-from-left':
            gsap.set(elem, {
                x: elem.dataset.parallaxAnimatedStartValue || '-100%'
            });
            break;
        case 'line-from-right':
            gsap.set(elem, {
                x: elem.dataset.parallaxAnimatedStartValue || '100%'
            });
            break;
        case 'line-from-top':
            gsap.set(elem, {
                height: 0
            });
            break;
        case 'line-from-bottom':
            gsap.set(elem, {
                y: elem.dataset.parallaxAnimatedStartValue || '100%'
            });
            break;
        case 'reveal-from-below':
            gsap.set(elem, {
                y: elem.dataset.parallaxAnimatedStartValue || 100,
                autoAlpha: 0
            });
            break;
        case 'slide-from-left':
            gsap.set(elem, {
                x: 0,
                autoAlpha: 1
            });
            break;
        case 'slide-from-right':
            gsap.set(elem, {
                x: elem.dataset.parallaxAnimatedStartValue || '100%',
            });
            break;
        case 'slide-from-top':
            gsap.set(elem, {
                y: 0,
                autoAlpha: 1
            });
            break;
        case 'slide-from-bottom':
            gsap.set(elem, {
                y: 0,
                autoAlpha: 1
            });
            break;
        case 'zoom-out':
            gsap.set(elem, {
                scale: 1.1,
                autoAlpha: 1
            });
            break;
        case 'zoom-in':
            gsap.set(elem, {
                scale: 1,
                autoAlpha: 1
            });
            break;
        case 'width-mask':
            gsap.set(elem, {
                width: 0,
            });
            break;
        default:
            gsap.set(elem, {
                autoAlpha: 0
            });
            break;
    }
}

const animateToTl = (tl, elem, animationType, delay = 0) => {
    let x = 0,
        y = 0,
        duration = 0.75;
    switch (animationType) {
        case 'opacity':
            tl.to(elem, {
                autoAlpha: 1,
                duration: 2.5
            });
            break;
        case 'line-from-top':
            tl.to(elem, {
                height: '100%',
                duration: duration,
                delay: delay
            });
            break;
        case 'reveal-from-below':
            tl.to(elem, {
                y: y,
                duration: duration,
                autoAlpha: 1,
                delay: 0.4
            });
            break;
        case 'slide-from-left':
            tl.to(elem, {
                x: -2000,
                duration: 3,
                autoAlpha: 0
            });
            break;
        case 'slide-from-right':
            tl.to(elem, {
                x: 0,
                duration: 1.5
            });
            break;
        case 'slide-from-top':
            tl.to(elem, {
                y: -700,
                duration: 3,
                autoAlpha: 1
            });
            break;
        case 'slide-from-bottom':
            tl.to(elem, {
                y: 700,
                duration: 3,
                autoAlpha: 1
            });
            break;
        case 'zoom-out':
            tl.to(elem, {
                scale: 1,
                duration: 2.5,
                autoAlpha: 1
            });
            break;
        case 'zoom-in':
            tl.to(elem, {
                scale: 1.1,
                duration: 1.5,
                autoAlpha: 1
            });
            break;
        case 'width-mask':
            tl.to(elem, {
                duration: 1.5,
                width: '100%'
            });
            break;
        default:
            tl.to(elem, {
                x: x,
                y: y,
                autoAlpha: 1,
                duration: duration
            });
            break;
    }
}

export { parallaxAnimations };