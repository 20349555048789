export default function paddingTopOnScroll(gsap, ScrollTrigger) {
	const paddingTopOnScrollItem =  document.querySelector('.padding-top-on-scroll-item');

	if (paddingTopOnScrollItem){
		if(window.scrollY==0) {
			ScrollTrigger.create({
				trigger: paddingTopOnScrollItem,
				start: "top top",
				onToggle: self => {  gsap.to(paddingTopOnScrollItem, { paddingTop: '0' }); }
			}); 
		}
		ScrollTrigger.create({
			trigger: paddingTopOnScrollItem,
			start: "top top",
			onToggle: self => { gsap.to(paddingTopOnScrollItem, { paddingTop: '125px' }); }
		});
	}
}
